import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto nacl secretbox seal`}</strong>{` -- produce an encrypted ciphertext`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto nacl secretbox seal <nonce> <key-file>
[--raw]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto nacl secretbox seal`}</strong>{` encrypts and authenticates a message using
a secret key and a nonce.`}</p>
    <p>{`This command uses an implementation of NaCl's crypto_secretbox function.`}</p>
    <p>{`For examples, see `}<strong parentName="p">{`step help crypto nacl secretbox`}</strong>{`.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`nonce`}</inlineCode>{`
Must be unique for each distinct message for a given key.`}</p>
    <p>{`To use a binary nonce use the prefix 'base64:' and the standard base64
encoding. e.g. base64:081D3pFPBkwx1bURR9HQjiYbAUxigo0Z`}</p>
    <p><inlineCode parentName="p">{`key-file`}</inlineCode>{`
The path to the shared key.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--raw`}</strong>{`
Do not base64 encode output`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      